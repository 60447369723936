const reactDeeplTranslate = async (text,targetLang)=>{
  
    let targetLangnew = targetLang;
    if(targetLang=='fr-FR')
    {
      targetLangnew ="FR";
    }
    const params = new URLSearchParams({
      'auth_key': '60f51f57-f4ab-491a-a8a2-f15c59fd9b02',
      // 'source_lang': languages.source.language,
      'target_lang': targetLangnew,
      'text': text
    });
    return new Promise((resolve,reject)=>{
      if (targetLang=='en') {
        return resolve(text);  
      }

      fetch('https://api.deepl.com/v2/translate', {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(async (response)=>{
        const translate  = await response.json();
        console.log('translatetranslatetranslatetranslate',translate);
        try {
          if (translate.translations.length>0) {
            resolve(translate.translations[0].text)  
          }
          else
          {
            resolve(text)
          }  
        } catch (error) {
          resolve(text)
        }
      }).catch((error)=>{
        reject(error)
      })
    });
    
  }
export default reactDeeplTranslate;