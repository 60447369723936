import React from 'react';
import Home from './Home';
import HomeLayout from '../../components/Layout/HomeLayout';

import { getListingFields } from '../../actions/getListingFields';
import _ from 'lodash'; 
import reactDeeplTranslate from '../../reactDeeplTranslate';



export default async function action({ store }) {
  const title = store.getState().siteSettings.data.siteTitle;
  const description = store.getState().siteSettings.data.metaDescription;
  const listingFields = store.getState().listingFields.data;
  const layoutType = store.getState().siteSettings.data.homePageType;
  // const wholeData = store.getState().homeBannerImages.data;
  
  const wholeData = _.cloneDeep(store.getState().homeBannerImages.data); // Create a deep copy of the state

  const getFindYouCar = await Promise.all(wholeData.getFindYouCar.map(async (item) => {
      const newItem = { ...item };
      if (item.name != "buttonLink" && item.value) {
        newItem.value = await reactDeeplTranslate(item.value, store.getState().intl.locale);
      }
      return newItem;
  }));
  
  wholeData.getFindYouCar = getFindYouCar;
  wholeData.title = await reactDeeplTranslate(wholeData.title, store.getState().intl.locale);
  wholeData.content = await reactDeeplTranslate(wholeData.content, store.getState().intl.locale);

  if (listingFields === undefined) {
    store.dispatch(getListingFields());
  }

  return {
    title,
    description,
    listingFields,
    chunk: 'home',
    component: <HomeLayout layoutType={layoutType}><Home layoutType={layoutType} wholeData={wholeData} /></HomeLayout>,
  };
};
